import Axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;

const server = Axios.create({
  baseURL,
  validateStatus: (status) => status >= 200 && status < 300,
});

export function contentUrl(path) {
  return `${baseURL}${path}`;
}

export default server;
